import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "flex justify-between items-center no-wrap" }
const _hoisted_3 = { class: "text-h4" }
const _hoisted_4 = { class: "flex q-gutter-x-md no-wrap" }
const _hoisted_5 = { class: "col-4 bg-grey-3 relative-position" }
const _hoisted_6 = {
  class: "absolute-bottom-right",
  style: {"padding":"4px 10px"}
}
const _hoisted_7 = {
  key: 1,
  style: {"min-height":"200px"},
  class: "full-height full-width"
}
const _hoisted_8 = { class: "justify-between flex column full-height" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "flex justify-between text-small" }
const _hoisted_11 = { class: "text-weight-medium" }
const _hoisted_12 = { class: "text-grey-8" }
const _hoisted_13 = {
  key: 1,
  class: "text-muted text-small ellipsis-2-lines"
}
const _hoisted_14 = { class: "q-mt-md" }
const _hoisted_15 = { class: "text-grey-8 q-gutter-xs flex items-center" }
const _hoisted_16 = { class: "advert-stats flex q-gutter-sm q-mt-sm" }
const _hoisted_17 = { key: 0 }

import {PropType, ref} from 'vue';
import {Advert} from 'src/models/advert';
import {date as quasarDate, useQuasar} from 'quasar';
import useAdvertUtils from 'src/components/adverts/utils/useAdvertUtils';
import ShareModal from 'components/ShareModal.vue';
import {clickSaleSign} from 'src/apps/adverts/helper';


export default /*@__PURE__*/_defineComponent({
  __name: 'AccountAdvertListItem',
  props: {
  advert: {
    type: Object as PropType<Advert>,
    required: true
  }
},
  emits: [],
  setup(__props, { emit: __emit }) {

const emit = __emit
const {deleteAdvert, toggleStatus, extendAdvert} = useAdvertUtils(emit);
const disableToggle = ref({})
const $q = useQuasar()



function toggle(advert) {
  disableToggle.value[advert.uuid] = true
  toggleStatus(advert).finally(() => {
    disableToggle.value[advert.uuid] = undefined
  })
}

const getDaysUntilExpiration = function (date) {
  return quasarDate.getDateDiff(date, new Date(), 'days')
}

const shareAdvert = function (advert) {
  const text = `${advert.seo_title} | ${advert.price}€`;
  $q.dialog({
    component: ShareModal,

    // props forwarded to your custom component
    componentProps: {
      url: advert.seo_link,
      text: text,
      mtmSource: 'AccountPage'
    },
  });
};


return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_banner = _resolveComponent("q-banner")!
  const _component_q_img = _resolveComponent("q-img")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_toggle = _resolveComponent("q-toggle")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_card, {
    bordered: "",
    flat: "",
    style: {"overflow":"hidden"}
  }, {
    default: _withCtx(() => [
      (__props.advert.has_expired)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_q_banner, {
              dense: "",
              class: "bg-warning text-white"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_q_icon, { name: "fas fa-calendar-xmark" }),
                    _cache[5] || (_cache[5] = _createTextVNode("  Abgelaufen "))
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_q_btn, {
                      unelevated: "",
                      color: "primary",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(extendAdvert)(__props.advert)))
                    }, {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                        _createTextVNode("Verlängern")
                      ])),
                      _: 1
                    })
                  ])
                ])
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_q_card_section, {
        horizontal: !_unref($q).screen.lt.sm,
        class: _normalizeClass({'q-pa-none': _unref($q).screen.lt.sm})
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_router_link, {
              to: {name: 'listingDetail', params: {slug: __props.advert.seo_slug, uuid: __props.advert.uuid}}
            }, {
              default: _withCtx(() => [
                (__props.advert.cover_image?.image_thumbnail)
                  ? (_openBlock(), _createBlock(_component_q_img, {
                      key: 0,
                      width: "100%",
                      height: "100%",
                      src: __props.advert.cover_image?.image_thumbnail?.path,
                      fit: "cover"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_component_q_icon, { name: "fas fa-images" }),
                          _createTextVNode("  " + _toDisplayString(__props.advert.images_count), 1)
                        ])
                      ]),
                      _: 1
                    }, 8, ["src"]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[7] || (_cache[7] = [
                      _createElementVNode("div", { class: "absolute-center text-muted" }, "Keine Bilder", -1)
                    ])))
              ]),
              _: 1
            }, 8, ["to"])
          ]),
          _createVNode(_component_q_card_section, { class: "col" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_8, [
                (__props.advert.internal_id || __props.advert.vehicle?.vin)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("div", null, [
                          _cache[8] || (_cache[8] = _createElementVNode("span", { class: "text-bold" }, "ID", -1)),
                          _createTextVNode(": " + _toDisplayString(__props.advert.internal_id || '-'), 1)
                        ]),
                        _createElementVNode("div", null, [
                          _cache[9] || (_cache[9] = _createElementVNode("span", { class: "text-bold" }, "VIN", -1)),
                          _createTextVNode(": " + _toDisplayString(__props.advert.vehicle.vin || '-'), 1)
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", null, [
                  _createVNode(_component_router_link, {
                    to: {name: 'listingDetail', params: {slug: __props.advert.seo_slug, uuid: __props.advert.uuid}}
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_11, _toDisplayString(__props.advert.vehicle.brand_display) + " " + _toDisplayString(__props.advert.vehicle.model), 1)
                    ]),
                    _: 1
                  }, 8, ["to"]),
                  _createElementVNode("span", _hoisted_12, [
                    _createTextVNode(" - " + _toDisplayString(_ctx.$filters.currency({
                  value: __props.advert.price,
                  minimumFractionDigits: 0
                })) + " ", 1),
                    (__props.advert.is_negotiable)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createTextVNode("VB")
                        ], 64))
                      : _createCommentVNode("", true)
                  ])
                ]),
                (__props.advert.description)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(__props.advert.description), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _createVNode(_component_q_btn, {
                      disable: __props.advert.has_expired,
                      flat: "",
                      icon: "fas fa-pencil",
                      round: "",
                      size: "12px",
                      to: {name: 'editAdvert', params: {uuid: __props.advert.uuid}}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_tooltip, { delay: 800 }, {
                          default: _withCtx(() => _cache[10] || (_cache[10] = [
                            _createTextVNode(" Bearbeiten ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["disable", "to"]),
                    (!__props.advert.has_expired && __props.advert.by_business)
                      ? (_openBlock(), _createBlock(_component_q_btn, {
                          key: 0,
                          flat: "",
                          icon: "fas fa-copy",
                          round: "",
                          size: "12px",
                          to: {name: 'createAdvert', query: {duplicate: __props.advert.uuid}}
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_tooltip, { delay: 800 }, {
                              default: _withCtx(() => _cache[11] || (_cache[11] = [
                                _createTextVNode(" Duplizieren ")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["to"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_q_btn, {
                      flat: "",
                      icon: "fas fa-share-alt",
                      round: "",
                      size: "12px",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (shareAdvert(__props.advert)))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_tooltip, { delay: 800 }, {
                          default: _withCtx(() => _cache[12] || (_cache[12] = [
                            _createTextVNode(" Teilen ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_q_btn, {
                      flat: "",
                      icon: "fas fa-ellipsis-vertical",
                      round: "",
                      size: "12px"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_tooltip, { delay: 800 }, {
                          default: _withCtx(() => _cache[13] || (_cache[13] = [
                            _createTextVNode(" Mehr ")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_q_menu, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_q_list, { style: {"min-width":"100px"} }, {
                              default: _withCtx(() => [
                                _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                  clickable: "",
                                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(clickSaleSign)(_unref($q), __props.advert)))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_item_section, null, {
                                      default: _withCtx(() => _cache[14] || (_cache[14] = [
                                        _createTextVNode("Verkaufsschild ausdrucken")
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })), [
                                  [_directive_close_popup]
                                ]),
                                _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                  clickable: "",
                                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(deleteAdvert)(__props.advert)))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_item_section, { class: "text-negative" }, {
                                      default: _withCtx(() => _cache[15] || (_cache[15] = [
                                        _createTextVNode("Löschen")
                                      ])),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })), [
                                  [_directive_close_popup]
                                ])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_q_toggle, {
                      disable: __props.advert.has_expired || disableToggle.value[__props.advert.uuid],
                      label: `${__props.advert.active ? 'Aktiv' : 'Inaktiv'}`,
                      "model-value": __props.advert.active,
                      "checked-icon": "check",
                      color: "positive",
                      "unchecked-icon": "clear",
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (toggle(__props.advert)))
                    }, null, 8, ["disable", "label", "model-value"])
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("div", null, [
                      _createVNode(_component_q_icon, { name: "visibility" }),
                      _cache[17] || (_cache[17] = _createTextVNode(" ")),
                      _createElementVNode("small", null, _toDisplayString(__props.advert.views), 1),
                      _createVNode(_component_q_tooltip, null, {
                        default: _withCtx(() => _cache[16] || (_cache[16] = [
                          _createTextVNode("Aufrufe von Nutzern")
                        ])),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", null, [
                      _createVNode(_component_q_icon, { name: "star" }),
                      _cache[19] || (_cache[19] = _createTextVNode(" ")),
                      _createElementVNode("small", null, _toDisplayString(__props.advert.favorised_count), 1),
                      _createVNode(_component_q_tooltip, null, {
                        default: _withCtx(() => _cache[18] || (_cache[18] = [
                          _createTextVNode("Von Nutzern gespeichert")
                        ])),
                        _: 1
                      })
                    ])
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("small", null, "Erstellt " + _toDisplayString(_ctx.$filters.dateTimeFormat(__props.advert.created_at)), 1),
                    _createElementVNode("small", null, [
                      _createTextVNode("  - Ablaufdatum: " + _toDisplayString(_ctx.$filters.dateTimeFormat(__props.advert.active_until)) + " ", 1),
                      (getDaysUntilExpiration(__props.advert.active_until) >= 0)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_17, "(noch " + _toDisplayString(getDaysUntilExpiration(__props.advert.active_until).toString()) + " Tage)", 1))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["horizontal", "class"])
    ]),
    _: 1
  }))
}
}

})