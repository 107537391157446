import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, toDisplayString as _toDisplayString, renderSlot as _renderSlot, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "relative-position rounded-borders overflow-hidden",
  style: {"aspect-ratio":"1"}
}
const _hoisted_2 = {
  key: 0,
  class: "absolute-full absolute bg-grey-3"
}
const _hoisted_3 = {
  key: 1,
  class: "absolute-full bg-grey-3"
}
const _hoisted_4 = { class: "absolute-center text-grey-6 text-center" }
const _hoisted_5 = {
  key: 3,
  class: "absolute-full",
  style: {"background":"rgba(255,255,255, 0.7)"}
}
const _hoisted_6 = { class: "absolute-center full-width text-center text-uppercase text-white text-h3" }
const _hoisted_7 = { class: "text-h4 text-grey-8" }
const _hoisted_8 = {
  class: "absolute-top-right q-pa-sm text-white",
  style: {"z-index":"1"}
}

import {computed, PropType} from 'vue';
import BookmarkBtn from 'src/apps/core/components/BookmarkBtn.vue';
import {Advert} from 'src/models/advert';
import CustomSwiperContainer from 'src/apps/core/components/CustomSwiperContainer.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AdvertCardImage',
  props: {
  data: {
    type: Object as PropType<Advert>,
    required: true,
  },
  isHighlighted: {
    type: Boolean,
    default: false
  },
  target: {
    default: undefined,
    type: String
  },
  showOwner: {
    type: Boolean,
    default: true,
  },
  hasSlider: {
    type: Boolean,
    default: false,
  },
},
  emits: [
  'mouseover',
  'mouseleave',
  'touchstart',
  'touchend',
],
  setup(__props) {



const props = __props


const images = computed(() => {
  if (!props.data?.thumbnails) return []
  return props.hasSlider ? props.data?.thumbnails : props.data?.thumbnails.slice(0, 1)
})

return (_ctx: any,_cache: any) => {
  const _component_q_img = _resolveComponent("q-img")!
  const _component_q_icon = _resolveComponent("q-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{'c-highlighted': __props.isHighlighted, 'q-hoverable cursor-pointer': !__props.data?.deleted && __props.data?.active}, "overflow-hidden c-card rounded-borders"]),
    onMouseover: _cache[0] || (_cache[0] = evt => _ctx.$emit('mouseover', evt)),
    onMouseleave: _cache[1] || (_cache[1] = evt => _ctx.$emit('mouseleave', evt)),
    onTouchstartPassive: _cache[2] || (_cache[2] = evt => _ctx.$emit('touchstart', evt)),
    onTouchend: _cache[3] || (_cache[3] = evt => _ctx.$emit('touchend', evt)),
    onContextmenu: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (images.value?.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_q_img, {
              "no-spinner": "",
              "no-transition": "",
              ratio: 4 / 4,
              src: images.value[0],
              alt: __props.data?.seo_slug,
              fit: "cover",
              loading: "eager",
              "data-index": 0
            }, null, 8, ["src", "alt"])
          ]))
        : _createCommentVNode("", true),
      _cache[6] || (_cache[6] = _createElementVNode("div", {
        class: "q-focus-helper",
        tabindex: "-1"
      }, null, -1)),
      (!__props.data?.thumbnails?.length || __props.data.thumbnails.length < 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_q_icon, {
                name: "image",
                size: "md"
              }),
              _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-xsmall" }, "Kein Bild", -1))
            ])
          ]))
        : (_openBlock(), _createBlock(CustomSwiperContainer, {
            key: 2,
            ref: "swiperRef",
            style: {"aspect-ratio":"1"},
            class: "bg-grey-3",
            "pagination-dynamic-bullets": "true",
            "long-swipes": "true",
            "space-between": "0",
            "long-swipes-ratio": "0.25",
            navigation: !_ctx.$q.platform.is.mobile || !_ctx.$q.platform.has.touch,
            "slides-data": images.value
          }, {
            default: _withCtx(({slideData, i}) => [
              _createVNode(_component_q_img, {
                "no-spinner": "",
                "no-transition": "",
                ratio: 4 / 4,
                src: slideData,
                alt: __props.data?.seo_slug,
                fit: "cover",
                "data-index": i,
                loading: i === 0 ? 'eager' : 'lazy',
                fetchpriority: i === 0 ? 'high' : 'low'
              }, null, 8, ["src", "alt", "data-index", "loading", "fetchpriority"])
            ]),
            _: 1
          }, 8, ["navigation", "slides-data"])),
      (__props.data?.deleted || !__props.data?.active)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("span", _hoisted_7, _toDisplayString(__props.data.deleted ? 'Inserat gelöscht' : 'Inserat inaktiv'), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_8, [
        _renderSlot(_ctx.$slots, "topRight", {}, () => [
          _createVNode(BookmarkBtn, {
            obj: __props.data,
            type: "advert"
          }, null, 8, ["obj"])
        ])
      ])
    ])
  ], 34))
}
}

})